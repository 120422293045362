// eslint-disable-next-line no-restricted-imports
import {
  ApolloError,
  DocumentNode,
  NoInfer,
  OperationVariables,
  SubscriptionHookOptions,
  TypedDocumentNode,
  useSubscription,
} from '@apollo/client';
import { startTransition, useCallback, useEffect, useState } from 'react';

export function useReconnectingSubscription<TData = any, TVariables extends OperationVariables = OperationVariables>(
  subscription: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: SubscriptionHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
) {
  const [internalSkip, setInternalSkip] = useState(false);
  const disconnect = useCallback(() => setInternalSkip(true), []);

  useEffect(
    function reconnect() {
      if (internalSkip) {
        startTransition(() => {
          setInternalSkip(false);
        });
      }
    },
    [internalSkip],
  );

  const onError = useCallback(
    (error: ApolloError) => {
      if (options?.onError) {
        options.onError(error);
      }

      disconnect();
    },
    [options, disconnect],
  );

  return useSubscription<TData, TVariables>(subscription, {
    ...options,
    skip: options?.skip || internalSkip,
    onError,
  });
}
