import { graphql } from '@/lib/gql';

import { RerenderOnSubscription } from './RerenderOnSubscription';

const onOperationUpsertedByVault = graphql(`
  subscription OperationChanges_Upserted_ByVaultId($vaultId: ID!) {
    onOperationUpserted(vaultId: $vaultId) {
      id
      __typename
    }
  }
`);

export function RerenderOnOperationChangesByVault({ vaultId }: { vaultId: string }) {
  if (!vaultId) return null;
  return <RerenderOnSubscription subscription={onOperationUpsertedByVault} options={{ variables: { vaultId } }} />;
}

const onOperationUpsertedByOperationIds = graphql(`
  subscription OperationChanges_Upserted_ByOperationIds($operationIds: [ID!]!) {
    onOperationUpserted(ids: $operationIds) {
      id
      __typename
    }
  }
`);

export function RerenderOnOperationChangesByOperationIds({ operationIds }: { operationIds: string[] }) {
  if (!operationIds) return null;
  return (
    <RerenderOnSubscription
      subscription={onOperationUpsertedByOperationIds}
      options={{ variables: { operationIds } }}
    />
  );
}
