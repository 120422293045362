'use client';

import { useEffect, useState, useRef } from 'react';

import { Signatures } from '@/lib/web3-access';

/**
 * Custom hook for fetching method code based on input data.
 *
 * @param {string | undefined} data - The input data to fetch the method code for.
 * @returns {Object} An object containing the fetched method code and loading state.
 * @property {string | null} fetchedMethodCode - The fetched method code, or null if not available.
 * @property {boolean} isMethodLoading - Indicates whether the method code is being fetched.
 */
export function useFetchMethodCode(data: string | undefined) {
  const hasCalledFetchMethod = useRef(false);
  const [methodCode, setMethodCode] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (hasCalledFetchMethod.current || !data) {
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      const callMethodCode = data.slice(0, 10);
      try {
        const fetchedCode = await Signatures.fetchSignatureMethod(callMethodCode);
        setMethodCode(fetchedCode);
      } catch (err) {
        console.error('Error fetching method signature:', err);
        setMethodCode(callMethodCode);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    hasCalledFetchMethod.current = true;
  }, [data]);

  return { methodCode, isLoading };
}
