import { ResultOf } from '@graphql-typed-document-node/core';
import cx from 'classnames';
import Image from 'next/image';

import { graphql } from '@/lib/gql';
import { useFormatter } from '@/lib/intl';

import styles from './diff-item.module.scss';

export function DiffItem({
  data,
  currency,
  layout,
}: {
  data: readonly (ResultOf<typeof fragment1155> | ResultOf<typeof fragment20> | ResultOf<typeof fragment721>)[];
  currency?: string | null | undefined;
  layout: 'exposures' | 'diff';
}) {
  const { fiat, cryptoFull } = useFormatter();

  if (data.length === 0) {
    return null;
  }

  return data.map((it, index) => {
    if (it.__typename === 'Erc20Diff') {
      return (
        <div key={index} className={cx(styles.erc20, layout === 'exposures' && styles.exposures)}>
          {!!it.maybeValue && <span className={styles.crypto}>{cryptoFull({ value: it.maybeValue, currency })}</span>}
          {layout === 'diff' && !!it.maybeValue && !!it.usdPrice && <span>&nbsp;•&nbsp;</span>}
          {!!it.usdPrice && <span className={styles.fiat}>{fiat({ value: it.usdPrice, currency: 'USD' })}</span>}
        </div>
      );
    }

    if (it.__typename === 'Erc1155Diff' || it.__typename === 'Erc721Diff') {
      const value = 'value' in it ? it.value : null;
      return (
        <div key={index} className={styles.erc1155}>
          <div className={cx(styles.logo, !it.logoUrl && styles.empty)}>
            <Image src={it.logoUrl ?? ''} alt="" fill />
          </div>

          <div className={styles.details}>
            <div className={styles.detailsHeader}>
              <p className={styles.id}>#{BigInt(it.tokenId).toString(10)}</p>

              {!!value && <p className={styles.crypto}>{cryptoFull({ value, currency })}</p>}
            </div>

            {!!it.usdPrice && <p className={styles.fiat}>{fiat({ value: it.usdPrice, currency: 'USD' })}</p>}
          </div>
        </div>
      );
    }
  });
}

const fragment1155 = graphql(`
  fragment OperationDetails_TxSimulation_ExposureItem1155 on Erc1155Diff {
    __typename
    tokenId
    value
    logoUrl
    summary
    usdPrice
  }
`);

const fragment20 = graphql(`
  fragment OperationDetails_TxSimulation_ExposureItem20 on Erc20Diff {
    __typename
    summary
    rawValue
    usdPrice
    maybeValue: value
  }
`);

const fragment721 = graphql(`
  fragment OperationDetails_TxSimulation_ExposureItem721 on Erc721Diff {
    __typename
    tokenId
    logoUrl
    summary
    usdPrice
  }
`);
