'use client';

import { startTransition, useCallback, useState } from 'react';
import cx from 'classnames';

import { Button } from '@/lib/io-kit/Button';
import { Icons } from '@/lib/io-kit/Icons';
import { TruncateLength, truncateValue } from '@/lib/truncate-value';
import { buildTestId } from '@/lib/test-ids';

import styles from './CopyValue.module.scss';

type Props = {
  value: string;
  size: React.ComponentPropsWithoutRef<typeof Button>['size'];
  showIcon: boolean;
  className?: string;
  'data-testid'?: string;
  showFullValue?: boolean;
  truncateLength?: TruncateLength;
};

export function CopyValue({
  value,
  size,
  showIcon,
  className,
  'data-testid': testId,
  showFullValue,
  truncateLength,
}: Props) {
  const [copied, setCopied] = useState(false);

  const copy = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      navigator.clipboard.writeText(value);
      startTransition(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
      });
    },
    [value],
  );

  return (
    <Button
      as="button"
      type="button"
      variant="link"
      size={size}
      className={cx(styles.container, { [styles.copied]: copied }, className)}
      onClick={copy}
      iconAfter={showIcon && <Icons.Copy />}
      disabled={copied}
      data-testid={buildTestId(testId, 'copy-button')}
      data-fullvalue={value}
    >
      <span title={value}>{showFullValue ? value : truncateValue(value, truncateLength)}</span>
      <div className={cx(styles.overlay, { [styles.copied]: copied })}>
        <Icons.Success className={styles.overlayIcon} /> Copied!
      </div>
    </Button>
  );
}
