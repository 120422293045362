'use client';
import cx from 'classnames';
import { useEffect, useState } from 'react';

import { CopyValue } from '@/components/CopyValue';
import { buildTestId } from '@/lib/test-ids';
import { TruncateLength, truncateValue } from '@/lib/truncate-value';
import { Tooltip } from '@/lib/io-kit/Tooltip';

import { OpenInExplorerIconButton } from './OpenInExplorerIconButton';
import styles from './CryptoAddress.module.scss';

type Props = {
  value: string;
  disableCopy?: boolean;
  showCopyIcon?: boolean;
  explorerUri?: undefined | null | string;
  className?: string;
  'data-testid'?: string;
  showFullValue?: boolean;
  truncateLength?: TruncateLength;
};

export function CryptoAddressOrId({
  value,
  disableCopy = false,
  showCopyIcon,
  explorerUri,
  className,
  'data-testid': testId,
  showFullValue,
  truncateLength,
}: Props) {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 800);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const shouldTruncate = isSmallScreen || !showFullValue || value.length > 53;

  const CoreElement = (
    <span className={cx(styles.container, className)} key={value}>
      {disableCopy ? (
        <span className={styles.rawValue} title={value}>
          {shouldTruncate ? truncateValue(value) : value}
        </span>
      ) : (
        <CopyValue
          value={value}
          size="small"
          showIcon={showCopyIcon ?? false}
          data-testid={testId}
          showFullValue={!shouldTruncate}
          truncateLength={truncateLength}
        />
      )}

      {!!explorerUri && (
        <OpenInExplorerIconButton explorerUri={explorerUri} data-testid={buildTestId(testId, 'explorer-link')} />
      )}
    </span>
  );

  return shouldTruncate ? (
    <Tooltip content={value} fullWidthContent key={value}>
      {CoreElement}
    </Tooltip>
  ) : (
    CoreElement
  );
}
export { OpenInExplorerIconButton } from './OpenInExplorerIconButton';
