import { Dispatch, MutableRefObject } from 'react';
import { SignClientTypes } from '@walletconnect/types';
import { IWeb3Wallet, Web3WalletTypes } from '@walletconnect/web3wallet';
import { getSdkError } from '@walletconnect/utils';
import { toast } from 'react-hot-toast';

import { showDismissableAlertToast } from '@/components/Toasts';
import { globalLogger } from '@/lib/logger';
import { Actions } from '@/features/wallet-connect/context';
import { refreshWeb3WalletSessions } from '@/features/wallet-connect/services';
import { getModalViewBasedOnRequestMethod } from '@/lib/web3-access';

const logger = globalLogger.child({ scope: 'wallet-connect' });

export const handleConnect = (
  dispatch: Dispatch<Actions>,
  web3wallet: IWeb3Wallet,
  isToastVisibleRef: MutableRefObject<boolean>,
  wasDisconnectedRef: MutableRefObject<boolean>,
) => {
  logger.debug('Network connection is restored!');

  const sessions = Object.values(web3wallet.getActiveSessions());
  if (sessions.length === 0) {
    return;
  }

  dispatch({
    type: 'set-settings',
    payload: { sessions: sessions },
  });

  if (isToastVisibleRef.current) {
    toast.dismiss();
  }

  if (wasDisconnectedRef.current) {
    // TODO handle translation with 'Toasts.WalletConnectToasts.NetworkConnectionRestoredSuccess'
    showDismissableAlertToast('Wallet Connect', 'Network connection is restored', 'success');
    isToastVisibleRef.current = true;
    wasDisconnectedRef.current = false;
  }
};

export const handleDisconnect = (
  dispatch: Dispatch<Actions>,
  web3wallet: IWeb3Wallet,
  isToastVisibleRef: MutableRefObject<boolean>,
  wasDisconnectedRef: MutableRefObject<boolean>,
) => {
  logger.debug('Network connection lost');

  const sessions = Object.values(web3wallet.getActiveSessions());
  if (sessions.length === 0) {
    return;
  }

  dispatch({
    type: 'set-settings',
    payload: { sessions: [] },
  });

  if (isToastVisibleRef.current) {
    toast.dismiss();
  }

  // TODO handle translation with 'Toasts.WalletConnectToasts.NetworkConnectionLostError'
  showDismissableAlertToast('Wallet Connect Network Error', 'Network connection lost', 'error');
  isToastVisibleRef.current = true;
  wasDisconnectedRef.current = true;
};

export const handleSessionProposal = (
  dispatch: Dispatch<Actions>,
  proposal: SignClientTypes.EventArguments['session_proposal'],
) => {
  logger.debug('session_proposal', proposal);

  dispatch({
    type: 'open-modal',
    payload: { view: 'SessionProposalModal', data: { currentRequestVerifyContext: proposal.verifyContext, proposal } },
  });
};

export const handleAuthRequest = (dispatch: Dispatch<Actions>, request: Web3WalletTypes.AuthRequest) => {
  dispatch({
    type: 'open-modal',
    payload: { view: 'AuthRequestModal', data: { request } },
  });
};

export const handleSessionRequest = async (
  dispatch: Dispatch<Actions>,
  web3wallet: IWeb3Wallet,
  requestEvent: SignClientTypes.EventArguments['session_request'],
) => {
  logger.debug('session_request', requestEvent);
  const { topic, params, verifyContext } = requestEvent;
  const { request } = params;
  const requestSession = web3wallet.engine.signClient.session.get(topic);

  const modalView = getModalViewBasedOnRequestMethod(request.method);

  dispatch({
    type: 'open-modal',
    payload: { view: modalView, data: { requestEvent, requestSession, currentRequestVerifyContext: verifyContext } },
  });
};

export const handleSessionDelete = (
  dispatch: Dispatch<Actions>,
  web3wallet: IWeb3Wallet,
  data?: SignClientTypes.EventArguments['session_delete'],
) => {
  logger.debug('session_delete event received', data);

  const sessions = Object.values(web3wallet.getActiveSessions());

  dispatch({
    type: 'set-settings',
    payload: { sessions: sessions },
  });
};

export const handleSessionRefresh = async (dispatch: Dispatch<Actions>, web3wallet: IWeb3Wallet) => {
  const sessions = Object.values(web3wallet.getActiveSessions());

  if (sessions.length === 0) {
    return;
  }

  await refreshWeb3WalletSessions(web3wallet);

  dispatch({
    type: 'set-settings',
    payload: { sessions: sessions },
  });
};

export const handleDisconnectActiveSessions = async (web3wallet: IWeb3Wallet) => {
  try {
    const sessions = Object.values(web3wallet.getActiveSessions());

    if (sessions.length === 0) {
      return;
    }

    for (const session of sessions) {
      await web3wallet.disconnectSession({
        topic: session.topic,
        reason: getSdkError('USER_DISCONNECTED'),
      });
    }
  } catch {
    logger.error('Error disconnecting active sessions');
  }
};
