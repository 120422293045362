import { useState } from 'react';
import { useTranslations } from 'next-intl';
import * as RadixCollapsible from '@radix-ui/react-collapsible';

import { Icons } from '@/lib/io-kit/Icons';
import { EIP155_SIGNING_METHODS, GenericMessage } from '@/lib/web3-access';

import styles from './JsonContent.module.scss';

export function JsonContent({ message }: { message: GenericMessage }) {
  const t = useTranslations('Components.Web3Modals.Components.JsonContent');
  const [isOpen, setIsOpen] = useState(true);

  if (
    message.method !== EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA &&
    message.method !== EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3 &&
    message.method !== EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4
  ) {
    return null;
  }

  if (!message.rawMessage) {
    return null;
  }

  let jsonData: any;
  try {
    jsonData = typeof message.rawMessage === 'string' ? JSON.parse(message.rawMessage) : message.rawMessage;
  } catch (err) {
    console.error('Error parsing rawMessage:', err);
    return null;
  }

  if (!jsonData || typeof jsonData !== 'object') {
    return null;
  }

  return (
    <RadixCollapsible.Root className={styles.collapsible} open={isOpen} onOpenChange={setIsOpen}>
      <RadixCollapsible.Trigger className={styles.collapsibleTrigger}>
        <div className={styles.triggerContent}>
          <span>{t('message')}</span>
          {isOpen ? <Icons.CaretUp width={20} height={20} /> : <Icons.CaretDown width={20} height={20} />}
        </div>
      </RadixCollapsible.Trigger>
      <RadixCollapsible.Content>
        <pre className={styles.jsonContent}>{JSON.stringify(jsonData, null, 2)}</pre>
      </RadixCollapsible.Content>
    </RadixCollapsible.Root>
  );
}
