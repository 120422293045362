/**
 * Adjusts the EIP712 signature v byte to avoid errors when validating the signature
 * @param signedData - The signed data
 * @param chainId - The chain id
 * @returns The adjusted EIP155 signature
 */
export function adjustEIP712Signature(signedData: string, chainId: number): string {
  // Remove '0x' prefix if present
  const cleanSignature = signedData.startsWith('0x') ? signedData.slice(2) : signedData;

  // Split the signature into r, s, v components
  const r = cleanSignature.slice(0, 64);
  const s = cleanSignature.slice(64, 128);
  let v = Number.parseInt(cleanSignature.slice(128), 16);
  console.log('Initial v number:', v, 'hex:', v.toString(16).padStart(2, '0'));

  // Adjust v for EIP-155
  // For Ethereum signatures, v can be CHAIN_ID * 2 + 35 or CHAIN_ID * 2 + 36
  // We need to convert it back to the standard recovery id (0 or 1)
  const recid = v - (chainId * 2 + 35);
  // Adjust v to be either 27 or 28 based on the recovery id
  v = recid + 27;
  // Combine r, s, v into a single signature string
  const signature = combineSignature(r, s, v);

  return signature;
}

/**
 * Combines the r, s, and v components of a signature into a single string
 * @param r - The r component of the signature
 * @param s - The s component of the signature
 * @param v - The v component of the signature
 * @returns The combined signature
 */
function combineSignature(r: string, s: string, v: number): string {
  // Ensure r and s are 32 bytes long (64 characters without '0x')
  const paddedR = r.startsWith('0x') ? r.slice(2).padStart(64, '0') : r.padStart(64, '0');
  const paddedS = s.startsWith('0x') ? s.slice(2).padStart(64, '0') : s.padStart(64, '0');

  // Convert v to a 2-character hex string
  const vHex = v.toString(16).padStart(2, '0');

  return '0x' + paddedR + paddedS + vHex;
}
