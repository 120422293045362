import { type TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
  useSuspenseInfiniteQuery,
  type InfiniteData,
  type UseSuspenseInfiniteQueryResult,
  type GetNextPageParamFunction,
  type UseSuspenseQueryResult,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { Duration } from 'luxon';

import type { InputMaybe, Scalars } from '@/lib/gql/graphql';

import { gqlClient } from './client';

export function useGraphQL<TResult, TVariables>({
  query,
  variables,
  refetchInterval: every,
}: {
  query: TypedDocumentNode<TResult, TVariables>;
  variables: TVariables;
  refetchInterval?: Duration | null | undefined;
}): UseSuspenseQueryResult<TResult> {
  return useSuspenseQuery({
    queryKey: [(query.definitions[0] as any).name.value, variables],
    async queryFn({ signal }): Promise<TResult> {
      return gqlClient.request({
        document: query,
        variables: variables as any,
        signal,
      });
    },
    refetchInterval: every?.as('milliseconds'),
  });
}

export function useInfiniteGraphQL<TResult, TVariables extends PageParams>({
  query,
  variables,
  getNextPageParam,
}: {
  query: TypedDocumentNode<TResult, TVariables>;
  variables: TVariables;
  getNextPageParam: GetNextPageParamFunction<TVariables, TResult>;
}): UseSuspenseInfiniteQueryResult<InfiniteData<TResult, TVariables>, Error> {
  return useSuspenseInfiniteQuery<TResult, Error, InfiniteData<TResult, TVariables>, any, TVariables>({
    queryKey: [(query.definitions[0] as any).name.value, variables],
    async queryFn({ pageParam, signal }): Promise<TResult> {
      // @ts-expect-error
      return gqlClient.request({
        document: query,
        variables: pageParam,
        signal,
      });
    },
    initialData: { pageParams: [{ ...variables }], pages: [] },
    initialPageParam: { ...variables },
    getNextPageParam,
  });
}

type PageParams = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};
