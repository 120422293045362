import cx from 'classnames';
import Image from 'next/image';

import styles from './Loading.module.scss';
import { HaltResumeRerenders } from './HaltResumeRerenders';

type Props = {
  type: 'page' | 'section';
  className?: string;
  isOverlay?: boolean;
  children?: React.ReactNode;
};

export function Loading({ type, className, isOverlay = false }: Props) {
  return (
    <div
      className={cx(
        styles.container,
        { [styles.page]: type === 'page', [styles.section]: type === 'section' },
        { [styles.overlay]: isOverlay },
        className,
      )}
      data-testid="loading-container"
    >
      <Image src="/loading.svg" width={50} height={50} alt="Loading…" />
    </div>
  );
}

export function LoadingPage() {
  return (
    <>
      <Loading type="page" />
      <HaltResumeRerenders />
    </>
  );
}

export function LoadingOverlayPage() {
  return (
    <>
      <Loading type="page" isOverlay />
      <HaltResumeRerenders />
    </>
  );
}

export function LoadingSection() {
  return (
    <>
      <Loading type="section" />
      <HaltResumeRerenders />
    </>
  );
}
