import { globalLogger } from '@/lib/logger';

const logger = globalLogger.child({ scope: 'de-fragmenter' });

export function deFragment(query: string) {
  let deFragmentedQuery = query.replaceAll('\n', ' ');
  const fragments = findFragmentsIntoString(query);
  logger.trace({ fragments }, 'resolved fragments on query');

  const fragmentReplacements = processQuery(deFragmentedQuery);

  deFragmentedQuery = processFragments(deFragmentedQuery, fragments, fragmentReplacements, 0);
  deFragmentedQuery = discardFragmentsFromBody(deFragmentedQuery);

  return deFragmentedQuery.trim();
}

function findFragmentsIntoString(query: string): string[] {
  const pattern = /\.{3}\w+/g;
  const matches = query.match(pattern);
  if (matches) {
    const results = matches.map((item) => item);
    return [...new Set(results)];
  }
  return [];
}

function discardFragmentsFromBody(body: string) {
  const targetIndex = body.indexOf('fragment');

  if (targetIndex !== -1) {
    body = body.slice(0, targetIndex) + '\n ';
  }
  return body;
}

function replaceLastClosingBracket(str: string) {
  const lastIndex = str.lastIndexOf('}');

  if (lastIndex !== -1) {
    const prefix = str.slice(0, lastIndex);
    const suffix = str.slice(Math.max(0, lastIndex + 1));
    return prefix + suffix;
  }
  return str;
}

function processQuery(query: string) {
  const parts = query.split('fragment');

  const fragments: Map<string, string> = new Map();
  for (const part of parts) {
    const fragmentName = part.trim().split(' ')[0];
    const fragmentValue = replaceLastClosingBracket(part.trim().replace(/\w+ on \w+\s*{/, ''));
    fragments.set(fragmentName, fragmentValue);
    logger.trace({ fragmentName, fragmentValue }, 'found fragment');
  }
  return fragments;
}

function processFragments(
  deFragmentedQuery: string,
  fragments: string[],
  fragmentReplacements: Map<string, string>,
  iteration: number,
): string {
  fragments.map((fragment) => {
    const fragmentValue = fragmentReplacements.get(fragment.replace('...', ''));

    if (fragmentValue) {
      deFragmentedQuery = deFragmentedQuery.replaceAll(`${fragment} `, fragmentValue);
    } else {
      logger.error({ fragment }, 'failed to find fragment value for fragment');
    }
  });

  const missingFragments = findFragmentsIntoString(deFragmentedQuery);
  if (missingFragments?.length && iteration < 10) {
    logger.trace({ missingFragments }, 'recursive call number ' + iteration);
    deFragmentedQuery = processFragments(deFragmentedQuery, missingFragments, fragmentReplacements, iteration + 1);
  }
  return deFragmentedQuery;
}
