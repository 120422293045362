import cx from 'classnames';
import { useTranslations } from 'next-intl';

import { FragmentType, getFragmentData } from '@/lib/gql';
import { TransactionValidationSuccessResultType as ResultType } from '@/lib/gql/graphql';
import { Icons } from '@/lib/io-kit/Icons';
import { ScreenedByBlockaid } from '@/components/ScreenedByBlockaid';

import { OperationValidationFragment } from './data-fragments';
import styles from './styles.module.scss';

export { OperationValidationFragment } from './data-fragments';

export function OperationValidation({ data: dataParam }: { data: FragmentType<typeof OperationValidationFragment> }) {
  const t = useTranslations('Components.OperationValidation');
  const data = getFragmentData(OperationValidationFragment, dataParam);

  if (data.__typename === 'TransactionValidationError') {
    return (
      <div className={cx(styles.container, styles.warning)}>
        <div className={styles.header}>
          <Icons.AlertCircle className={styles.headerIcon} />
          <span>{t('errorTitle')}</span>
        </div>

        <p>{t('errorDescription')}</p>
      </div>
    );
  }

  if (data.resultType === ResultType.Benign) {
    return null;
  }

  const type = data.resultType;

  return (
    <div
      className={cx(
        styles.container,
        type === ResultType.Malicious && styles.error,
        type === ResultType.Warning && styles.warning,
      )}
    >
      <div className={styles.header}>
        <Icons.AlertCircle className={styles.headerIcon} />
        <span>{type === ResultType.Malicious ? t('maliciousDescription') : t('suspiciousDescription')}</span>
      </div>

      <p>{data.description}</p>

      <ScreenedByBlockaid className={styles.footer} />
    </div>
  );
}
