import { graphql } from '@/lib/gql';

export const OperationValidationFragment = graphql(`
  fragment OperationValidation on TransactionValidation {
    __typename
    ... on TransactionValidationError {
      error
      status
    }
    ... on TransactionValidationSuccess {
      features {
        featureId
        description
        type
        address
      }
      classification
      description
      reason
      resultType
      status
    }
  }
`);
