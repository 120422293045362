import { useTranslations } from 'next-intl';
import cx from 'classnames';

import { FragmentType, getFragmentData } from '@/lib/gql';
import { ScreenedByBlockaid } from '@/components/ScreenedByBlockaid';
import { Icons } from '@/lib/io-kit/Icons';

import { AssetDiff } from './asset-diff';
import { Exposures } from './exposures';
import styles from './index.module.scss';
import { OperationSimulationFragment } from './data-fragments';

export { OperationSimulationFragment } from './data-fragments';

export function OperationSimulation({
  data: dataParam,
  className,
}: {
  data: FragmentType<typeof OperationSimulationFragment>;
  className?: string;
}) {
  const t = useTranslations('Components.OperationSimulation');
  const data = getFragmentData(OperationSimulationFragment, dataParam);

  if (!data) {
    return null;
  }

  if (data.__typename === 'TransactionSimulationError') {
    return (
      <div className={cx(styles.container, styles.error, className)}>
        <div className={styles.header}>
          <Icons.AlertCircle className={styles.errorIcon} />
          <p>{t('title')}</p>
        </div>

        <p>{t('error')}</p>
      </div>
    );
  }

  const { assetDiffs, exposures } = data.accountSummary;

  return (
    <>
      {assetDiffs.length > 0 && (
        <div className={cx(styles.container, className)}>
          <div className={styles.header}>
            <p>{t('title')}</p>
            <ScreenedByBlockaid />
          </div>

          <div className={styles.diffsContainer}>
            {assetDiffs.map((it, index) => {
              return <AssetDiff key={index} data={it} />;
            })}
          </div>
        </div>
      )}

      {exposures.length > 0 && (
        <div className={cx(styles.container, className)}>
          <div className={styles.header}>
            <p>{t('exposures.title')}</p>
            <ScreenedByBlockaid />
          </div>

          <Exposures data={exposures} />
        </div>
      )}
    </>
  );
}
