import { formatJsonRpcError, formatJsonRpcResult } from '@walletconnect/jsonrpc-utils';
import { getSdkError } from '@walletconnect/utils';
import { RequestData, RequestMetadata } from '@iofinnet/io-web3-provider';

import { globalLogger } from '@/lib/logger';
import { Operation } from '@/lib/models';
import { adjustEIP712Signature } from '@/lib/web3-utils';
import {
  encodeEIP191Message,
  getSignParamsMessage,
  getSignTypedDataParamsData,
  eip155Wallets,
  EIP155_SIGNING_METHODS,
  RequestParams,
  SessionMetadata,
} from '@/lib/web3-access';

const logger = globalLogger.child({ scope: 'eip155-request-handler' });

export async function approveEIP155Request({
  requestParams,
  sessionMetadata,
  data,
}: {
  requestParams: RequestParams;
  sessionMetadata?: SessionMetadata;
  data: RequestData;
}) {
  const { id, chainId, method, params } = requestParams;

  logger.debug(
    '[approveEIP155Request] Received request for vaultId',
    data.vaultId,
    'chainId',
    chainId,
    'with method',
    method,
  );

  const metadata: RequestMetadata = {};

  if (sessionMetadata) {
    metadata.sourceUrl = sessionMetadata.url;
  }

  try {
    await eip155Wallets?.switchEthereumChain(chainId);
  } catch {
    return formatJsonRpcError(id, getSdkError('UNSUPPORTED_CHAINS').message);
  }

  switch (method) {
    case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION: {
      try {
        const transaction = params[0];
        metadata.source = Operation.getSourceFromSourceUrl(metadata.sourceUrl, 'transaction');

        const transactionHash = await eip155Wallets.sendTransaction({
          transaction,
          data,
          metadata,
        });
        return formatJsonRpcResult(id, transactionHash);
      } catch (err: any) {
        console.error(err);
        return formatJsonRpcError(id, err.message);
      }
    }

    case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION: {
      try {
        const transaction = params[0];
        metadata.source = Operation.getSourceFromSourceUrl(metadata.sourceUrl, 'sign');

        const signature = await eip155Wallets.signTransaction({
          transaction,
          data,
          metadata,
        });
        return formatJsonRpcResult(id, signature);
      } catch (err: any) {
        console.error(err);
        return formatJsonRpcError(id, err.message);
      }
    }

    case EIP155_SIGNING_METHODS.PERSONAL_SIGN:
    case EIP155_SIGNING_METHODS.ETH_SIGN: {
      try {
        const message = getSignParamsMessage(params);
        const encodedMessage = encodeEIP191Message(message);
        metadata.source = Operation.getSourceFromSourceUrl(metadata.sourceUrl, 'sign');

        const signedMessage = await eip155Wallets.signMessage({
          message: encodedMessage,
          data,
          metadata,
        });
        return formatJsonRpcResult(id, signedMessage);
      } catch (err: any) {
        console.error(err);
        return formatJsonRpcError(id, err.message);
      }
    }

    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4: {
      try {
        const { domain, types, message: typeData, primaryType } = getSignTypedDataParamsData(params);
        metadata.source = Operation.getSourceFromSourceUrl(metadata.sourceUrl, 'sign');

        const signedData = await eip155Wallets.signTypedData({
          domain,
          types,
          typeData,
          _primaryType: primaryType,
          data,
          metadata,
        });

        // Override signature last byte to avoid errors when validating the signature
        const signature = adjustEIP712Signature(signedData, Number(chainId.split(':')[1]));

        return formatJsonRpcResult(id, signature);
      } catch (err: any) {
        console.error(err);
        return formatJsonRpcError(id, err.message);
      }
    }

    default: {
      return formatJsonRpcError(id, getSdkError('INVALID_METHOD').message);
    }
  }
}

export function rejectEIP155Request(id: number) {
  return formatJsonRpcError(id, getSdkError('USER_REJECTED').message);
}
