'use client';
import { useTranslations } from 'next-intl';
import { useMemo, useState } from 'react';
import cx from 'classnames';

import { DetailsCard } from '@/components/DetailsCard';
import { Heading } from '@/lib/io-kit/Heading';
import { Icons } from '@/lib/io-kit/Icons';
import { EIP155_SIGNING_METHODS, GenericMessage, extractFields, getMessageDetail } from '@/lib/web3-access';
import { OperationValidation } from '@/components/OperationValidation';
import { OperationSimulation } from '@/components/OperationSimulation';
import { LoadingSection } from '@/components/Loading';
import { convertWeiToEther } from '@/lib/web3-utils';
import { CryptoAmount } from '@/components/CryptoAmount';
import { useFormatter } from '@/lib/intl';

import { useFetchMethodCode, useScanTransaction } from '../hooks';

import styles from './RequestDataCard.module.scss';
import { DetailsDataCard } from './DetailsDataCard';
import { JsonContent } from './JsonContent';

type Props = {
  message: GenericMessage;
};

export function RequestDataCard({ message }: Props) {
  const t = useTranslations('Components.Web3Modals.Components.RequestDataCard');

  const data = getMessageDetail(message.message, 'data');
  const { methodCode, isLoading: isMethodLoading } = useFetchMethodCode(data);
  const { simulation, validation, error: scanError, isLoading: isScanLoading } = useScanTransaction(message);
  const formatter = useFormatter();

  const allFields = useMemo(
    () => [
      'site',
      'network',
      'method',
      'token',
      'from',
      'to',
      'spender',
      'createdAt',
      'sigDeadline',
      'createdBy',
      'value',
      'gas',
      'primaryType',
    ],
    [],
  );
  const mainFields = useMemo(
    () => ['site', 'network', 'method', 'token', 'from', 'to', 'spender', 'primaryType', 'gas'],
    [],
  );
  const additionalFields = useMemo(() => ['createdAt', 'sigDeadline', 'createdBy'], []);
  const { mainDisplayOrder, additionalDisplayOrder } = useMemo(
    () => ({
      mainDisplayOrder: scanError ? allFields : mainFields,
      additionalDisplayOrder: scanError ? [] : additionalFields,
    }),
    [scanError, allFields, mainFields, additionalFields],
  );

  const [details, setDetails] = useState<Array<{ key: string; label: string; value: JSX.Element }>>([]);
  const [additionalInfo, setAdditionalInfo] = useState<Array<{ key: string; label: string; value: JSX.Element }>>([]);

  useMemo(() => {
    if (isMethodLoading || isScanLoading) return;

    const updatedDetails = extractFields(message, mainDisplayOrder).map((detail) => {
      const detailKey = detail.key;

      let value;
      switch (detailKey) {
        case 'method': {
          value = <DetailsDataCard detailKey="method" value={methodCode ?? detail.value} message={message} />;
          break;
        }
        default: {
          value = <DetailsDataCard detailKey={detailKey} value={detail.value} message={message} />;
        }
      }

      return {
        key: detailKey,
        label: t(`${detailKey}` as any),
        value,
      };
    });
    setDetails(updatedDetails);

    const updatedAdditionalInfo = [
      ...extractFields(message, additionalDisplayOrder),
      // Add memo only if there's no scanError and even if it's empty
      ...(scanError
        ? []
        : [
            {
              key: 'memo',
              value: message.message.find((msg: any) => msg.title === 'memo')?.description ?? '',
            },
          ]),
    ].map((detail) => ({
      key: detail.key,
      label: t(`${detail.key}` as any),
      value: <DetailsDataCard detailKey={detail.key} value={detail.value} message={message} />,
    }));

    setAdditionalInfo(updatedAdditionalInfo);
  }, [isMethodLoading, isScanLoading, message, mainDisplayOrder, additionalDisplayOrder, scanError, t, methodCode]);

  const totalAmount = useMemo(() => {
    const amount = message.message.find((msg) => msg.title === 'amount')?.description ?? '0';
    const value = message.message.find((msg) => msg.title === 'value')?.description ?? '0';
    const gas = message.message.find((msg) => msg.title === 'gas')?.description ?? '0';

    const amountInEther = convertWeiToEther(amount);
    const valueInEther = convertWeiToEther(value);
    const gasInEther = convertWeiToEther(gas);

    const total = amountInEther.plus(valueInEther).plus(gasInEther).toFixed(6);

    return formatter.cryptoFull({ value: total });
  }, [formatter, message.message]);

  if (isScanLoading || isMethodLoading) {
    return (
      <DetailsCard.Section heading={<DetailsTitle />} className={styles.container}>
        <LoadingSection />
      </DetailsCard.Section>
    );
  }

  return (
    <DetailsCard.Section heading={<DetailsTitle />} className={styles.container}>
      {validation && <OperationValidation data={validation} />}
      <div className={cx(validation && styles.subContainer)}>
        <DetailsCard.ValuesList data={details} />
      </div>
      <JsonContent message={message} />
      {simulation && <OperationSimulation data={simulation} className={styles.subContainer} />}
      {!simulation && message.method === EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION && (
        // Fallback to total amount display if no simulation is available
        <>
          <div className={styles.divider} />
          <Total
            totalAmount={totalAmount}
            currency={message.message.find((msg) => msg.title === 'currency')?.description}
          />
        </>
      )}
      {additionalInfo.length > 0 && (
        <div className={styles.subContainer}>
          <DetailsCard.ValuesList data={additionalInfo} />
        </div>
      )}
    </DetailsCard.Section>
  );
}

function DetailsTitle() {
  const t = useTranslations('Components.Web3Modals.Components.RequestDataCard');
  return (
    <Heading as="h3" variant="heading6" className={styles.heading}>
      <Icons.ThirdParty className={styles.icon} />
      {t('title')}
    </Heading>
  );
}

function Total({ totalAmount, currency }: { totalAmount: string; currency: string | undefined }) {
  const t = useTranslations('Components.Web3Modals.Components.RequestDataCard');
  const data = [
    {
      label: t('totalAmount'),
      value: <TotalGroup totalCrypto={totalAmount} cryptoCurrency={currency} />,
    },
  ];

  return (
    <DetailsCard.Section className={styles.total}>
      <DetailsCard.ValuesList data={data} />
    </DetailsCard.Section>
  );
}

function TotalGroup({
  totalCrypto,
  cryptoCurrency,
  totalFiat,
  fiatCurrency = 'USD',
}: {
  totalCrypto: string;
  cryptoCurrency: string | undefined;
  totalFiat?: string;
  fiatCurrency?: string;
}) {
  const formatter = useFormatter();

  return (
    <div className={styles.totalGroup}>
      <CryptoAmount amount={totalCrypto} className={styles.amountOriginal} currency={cryptoCurrency} />
      {totalFiat && (
        <div className={styles.amountUsd}>{formatter.fiat({ value: totalFiat, currency: fiatCurrency })}</div>
      )}
    </div>
  );
}
