import { graphql } from '@/lib/gql';

export const OperationSimulationFragment = graphql(`
  fragment OperationSimulation on TransactionSimulation {
    __typename
    ... on TransactionSimulationError {
      error
      status
    }
    ... on TransactionSimulationSuccess {
      status
      totalUsdDiff {
        total
        in
        out
        address
      }
      accountSummary {
        totalUsdDiff {
          in
          out
          total
        }
        assetDiffs {
          ...OperationSimulation_AssetDiff
        }
        exposures {
          ...OperationSimulation_Exposures
        }
      }
    }
  }
`);
