import Image from 'next/image';

import styles from './styles.module.scss';

type Props = {
  className?: string;
};

export function ScreenedByBlockaid({ className }: Props) {
  return (
    <div className={className}>
      <div className={styles.container}>
        <span>Screened by Blockaid</span>
        <div className={styles.icon}>
          <Image fill alt="" src="/blockaid.svg" />
        </div>
      </div>
    </div>
  );
}
