import Image from 'next/image';

import { FragmentType, getFragmentData, graphql } from '@/lib/gql';
import { Icons } from '@/lib/io-kit/Icons';

import { DiffItem } from './diff-item';
import styles from './asset-diff.module.scss';

export function AssetDiff({ data: dataParam }: { data: FragmentType<typeof fragment> }) {
  const data = getFragmentData(fragment, dataParam);

  return (
    <>
      <div className={styles.asset}>
        <div className={styles.assetLogo}>
          <Image src={data.asset.logoUrl ?? ''} fill alt="" />
        </div>

        <span className={styles.assetName}>{data.asset.symbol ?? data.asset.name ?? data.asset.type}</span>
      </div>

      <div className={styles.diff}>
        {data.in.length > 0 && (
          <div className={styles.diffItem}>
            <Icons.TriangleFilled className={styles.arrowUp} />
            <DiffItem data={data.in} layout="diff" />
          </div>
        )}
        {data.out.length > 0 && (
          <div className={styles.diffItem}>
            <Icons.TriangleFilled className={styles.arrowDown} />
            <DiffItem data={data.out} layout="diff" />
          </div>
        )}
      </div>
    </>
  );
}

const fragment = graphql(`
  fragment OperationSimulation_AssetDiff on AssetDiff {
    __typename
    asset {
      type
      address
      logoUrl
      name
      symbol
    }
    in {
      ... on Erc1155Diff {
        __typename
        tokenId
        value
        logoUrl
        summary
        usdPrice
      }
      ... on Erc20Diff {
        __typename
        summary
        rawValue
        usdPrice
        maybeValue: value
      }
      ... on Erc721Diff {
        __typename
        tokenId
        logoUrl
        summary
        usdPrice
      }
    }
    out {
      ... on Erc1155Diff {
        __typename
        tokenId
        value
        logoUrl
        summary
        usdPrice
      }
      ... on Erc20Diff {
        __typename
        summary
        rawValue
        usdPrice
        maybeValue: value
      }
      ... on Erc721Diff {
        __typename
        tokenId
        logoUrl
        summary
        usdPrice
      }
    }
  }
`);
