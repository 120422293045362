'use client';
import { useTranslations } from 'next-intl';

import { graphql, getFragmentData } from '@/lib/gql';
import { useGraphQL } from '@/lib/gql-client/browser';
import { OperationModal } from '@/components/OperationModal';
import { Heading } from '@/lib/io-kit/Heading';
import { Icons } from '@/lib/io-kit/Icons';
import { LinkInternal } from '@/components/Link';
import { Avatar } from '@/lib/io-kit/Avatar';
import { LinkTo } from '@/lib/links';
import { buildTestId } from '@/lib/test-ids';
import { Button } from '@/lib/io-kit/Button';
import { UserAvatarFragment, getUserRole, getUserType } from '@/components/UserAvatar';
import { Alert } from '@/lib/io-kit/Alert';
import { LoadingSection } from '@/components/Loading';
import { GetCurrentVaultSignersQuery } from '@/lib/gql/graphql';

import styles from './SessionSubmittedModal.module.scss';

const query = graphql(`
  query GetCurrentVaultSigners($vaultId: ID!) {
    vault(vaultId: $vaultId) {
      signers {
        id
        weight
        device {
          id
          name
          user {
            ...UserAvatar
          }
        }
      }
    }
  }
`);

type SessionSubmittedModalProps = {
  isOpen: boolean;
  vaultId: string;
  closeModal: () => void;
  isDisplayInternalLink?: boolean;
};

export function SessionSubmittedModal({
  isOpen,
  vaultId,
  closeModal,
  isDisplayInternalLink = true,
}: SessionSubmittedModalProps) {
  const t = useTranslations('Components.Web3Modals.SessionSubmittedModal');

  const { data, isLoading, error } = useGraphQL({
    query,
    variables: { vaultId },
  });

  return (
    <OperationModal
      isOpen={isOpen}
      preventCancel
      hideCloseButton
      // Closing is handled by the reducer
      onClose={() => {}}
      largeIcon="SuccessWithRippleEffect"
      heading={
        <Heading as="div" variant="heading5" className={styles.heading}>
          <Icons.Phone className={styles.headingIcon} />
          {t('title')}
        </Heading>
      }
      description={t('description')}
      footer={
        <Footer
          data-testid="sign-request-conformation-modal"
          signers={data?.vault?.signers}
          trackProgressHref={LinkTo.vaultActivity({ vaultId })}
          closeModal={closeModal}
          isDisplayInternalLink={isDisplayInternalLink}
        />
      }
    >
      {isLoading && <LoadingSection />}
      {error && <Alert variant="error" title={t('errorLoadingVaultSigners')} />}
      {data && data.vault && <Approvals signers={data.vault.signers} />}

      <div className={styles.alertWrapper}>
        <Alert title={t('doNotClose')} variant="warning" />
      </div>
    </OperationModal>
  );
}

type Props = {
  'data-testid'?: string;
  signers?: GetCurrentVaultSignersQuery['vault']['signers'];
  trackProgressHref?: string;
  isDisplayInternalLink?: boolean;
  closeModal?: () => void;
};

function Approvals({ signers }: Props) {
  const t = useTranslations('Components.Web3Modals.SessionSubmittedModal');

  if (!signers) return null;

  return (
    <div>
      <div className={styles.approvalsTitle}>{t('approvalsSent')}</div>
      <ul className={styles.approvalsList}>
        {signers.map(({ id, device }) => {
          const userData = getFragmentData(UserAvatarFragment, device.user);
          const {
            id: userId,
            details: { profilePictureUri, initials, name },
          } = userData;

          return (
            <li key={id} className={styles.approvalsListItem}>
              <Avatar
                src={profilePictureUri}
                userId={userId}
                userInitials={initials}
                userName={name}
                userRole={getUserRole(userData)}
                userType={getUserType(userData)}
                size="large"
                highlight="dimmed"
              />
              <span className={styles.approvalsDevice}>{device.name}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function Footer({ trackProgressHref, isDisplayInternalLink = true, closeModal, 'data-testid': testId }: Props) {
  const t = useTranslations('Components.Web3Modals.SessionSubmittedModal');

  return (
    <>
      <Button variant="light" fullWidth={!isDisplayInternalLink} onClick={closeModal}>
        {t('linkToDapps')}
      </Button>
      {isDisplayInternalLink && trackProgressHref && (
        <LinkInternal variant="unstyled" href={trackProgressHref} passHref>
          <Button
            as="a"
            variant="primary"
            data-testid={buildTestId(testId, 'submitted.track.button')}
            onClick={closeModal}
          >
            {t('linkToActivityPage')}
          </Button>
        </LinkInternal>
      )}
    </>
  );
}
